import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

import * as T from "styles/typography";
import * as V from "styles/variables";

export const ButtonLink = styled(Link)`
  ${T.Link}
  display: inline-block;
  font-size: 1.6rem;
`;
