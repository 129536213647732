import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import media from "styled-media-query";

import * as V from "styles/variables";
import * as T from "styles/typography";

const Contents = styled.article`
  ${T.Text1}

  &:not(:first-child) {
    margin-top: ${V.Space.md};
  }

  &:not(:last-child) {
    margin-bottom: ${V.Space.md};
  }

  h1 {
    ${T.Heading1}

    &:not(:first-child) {
      margin-top: ${V.Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.xxs};
    }
  }

  h2 {
    ${T.Heading2}

    &:not(:first-child) {
      margin-top: ${V.Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  h3 {
    ${T.Heading3}

    &:not(:first-child) {
      margin-top: ${V.Space.md};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.sm};
    }
  }

  hr {
    // ${T.Heading3}

    &:not(:first-child) {
      margin-top: ${V.Space.xs};
    }

    &:not(:last-child) {
      // margin-bottom: ${V.Space.sm};
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ${V.Space.xsm};
      // font-weight: 600;
      font-size: 1.7rem;
      font-family: sans-serif;
    }
  }

  input {
    -webkit-appearance: none;
    background-color: var(--bg);
    border: ${V.Border.box};
    border-radius: 10px;
    color: var(--primaryColor);
    font-family: 'Courier New', monospace;
    display: block;
    font-size: 1.6rem;
    height: ${V.Space.xlg};
    line-height: ${V.Space.lg};
    padding-left: ${V.Space.sm};
    padding-right: ${V.Space.sm};
    width: 100%;
    spellcheck="false";
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
    resize: none;

  }

  textarea {
    -webkit-appearance: none;
    background-color: var(--bg);
    border: ${V.Border.box};
    border-radius: 10px;
    color: var(--primaryColor);
    font-family: 'Courier New', monospace;
    display: block;
    font-size: 1.6rem;
    height: ${V.Space.xxlg};
    padding-top: ${V.Space.sm};
    padding-left: ${V.Space.sm};
    padding-right: ${V.Space.sm};
    width: 100%;
    spellcheck: "false";
    font-weight: 600;
    resize: none;

  }

  a {
    ${T.Link}
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ol,
  ul {
    padding-left: 2.5rem;

    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  li {
    &:not(:first-child) {
      margin-top: ${V.Space.sm};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.xs};
    }
  }

  img,
  .gatsby-resp-image-wrapper {
    width: 100%;

    & + em {
      font-size: 1.4rem;
    }
  }

  .gatsby-highlight {
    pre[class*="language-"] {
      // background-color: var(--bgCode);
      // border-left: ${V.Space.xxs} solid var(--highlightColor);
      font-size: 16px;
      padding-left: ${V.Space.default};

      ${media.lessThan("medium")`
        border-radius: 0;
        font-size: 16px;
        margin-left: -${V.Space.sm};
        margin-right: -${V.Space.sm};
      `}
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  .language-text {
    background-color: var(--bgSecondary);
    color: var(--colorPrimary);
    font-size: 16px;
    padding-bottom: 0em;
    padding-left: 0em;
    padding-right: 0em;
    padding-top: 0em;
    text-shadow: none;
  }

  hr {
    background-color: var(--borderColor);
    border: 0;
    height: 2px;
    margin-bottom: ${V.Space.default};
    margin-top: ${V.Space.default};
  }

  .twitter-tweet {
    ${T.Text2}

    p {
      ${T.Heading3}
      border-bottom: ${V.Border.box};
      font-style: normal;
      margin-bottom: ${V.Space.sm};
      padding-bottom: ${V.Space.sm};
    }

    a {
      ${T.Text2}

      &:before {
        content: "";
        display: block;
      }
    }
  }

  .support-title {
    ${T.Subtitle1}
  }
`;

const Content = ({ children }) => {
  return <Contents>{children}</Contents>;
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
