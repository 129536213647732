import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { graphql, useStaticQuery, navigate } from "gatsby";
import Img from "gatsby-image";
import Social from "components/Social";
import { useEffect, useState } from "react";
import useSWR from "swr";

import bitcoinMessage from "bitcoinjs-message";

import Content from "./styled";
import withLocation from "components/withLocation";

import * as V from "styles/variables";
import * as T from "styles/typography";
import * as S from "./styled";

const Image = ({ src, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );

  if (!match) return null;

  const { node: { childImageSharp, publicURL, extension } = {} } = match;

  if (extension === "svg" || !childImageSharp) {
    return <img src={publicURL} {...rest} />;
  }

  return <Img fluid={childImageSharp.fluid} {...rest} />;
};

const addressBalanceFetcher = async (address) => {
  if (!address) {
    return {};
  }

  const res = await fetch(`https://blockstream.info/api/address/${address}`);
  return await res.json();
};

const Verify = ({ search }) => {
  const { a, m, s } = search;

  console.log(a, m, s);

  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [signature, setSignature] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const { data, error } = useSWR(`${address}`, addressBalanceFetcher);

  useEffect(() => {
    verify();
  }, [address, message, signature]);

  useEffect(() => {
    if (a) setAddress(String(a));
    if (m) setMessage(String(m));
    if (s) setSignature(String(s));
    // verify();
  }, [a, m, s]);

  const bitcoinMessageVerify = (message, address, signature) => {
    // undefined, true so it can verify Electrum signatures without errors
    try {
      return bitcoinMessage.verify(
        message,
        address,
        signature,
        undefined,
        true
      );
    } catch (e) {
      if (
        e.message ===
        "checkSegwitAlways can only be used with a compressed pubkey signature flagbyte"
      ) {
        // If message created with uncompressed private key, it will throw this error
        // in this case we should re-try with checkSegwitAlways flag off
        // node_modules/bitcoinjs-message/index.js:187
        return bitcoinMessage.verify(message, address, signature);
      }
      throw e;
    }
  };

  const verify = () => {
    setIsVerified(false);
    try {
      navigate(
        `/verify?a=${address}&m=${encodeURIComponent(
          message
        )}&s=${encodeURIComponent(signature)}`
      );
      const verified = bitcoinMessageVerify(message, address, signature);
      console.log({ message, address, signature, verified });
      setIsVerified(verified);
    } catch (error) {
      console.warn(error.message);
    }
  };

  return (
    <Content>
      <div
        style={{
          textAlign: "center",
          fontSize: "3.5rem",
          fontFamily: "sans serif",
          //   color: "#39C655",
        }}
      >
        <b
          style={{
            textAlign: "center",
            fontSize: "3.5rem",
            fontFamily: "sans serif",
            //   color: "#39C655",
          }}
        >
          Verify A Bitcoin Digital Signature
        </b>
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          verify();
        }}
      >
        <div>
          <p>Address:</p>
          <input
            type="text"
            size={50}
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />
        </div>

        {/* <br /> */}

        {data?.chain_stats && (
          <span
            style={{
              color: "#0051FF",
            }}
          >
            {" "}
            <i>
              {(data?.chain_stats?.funded_txo_sum -
                data?.chain_stats?.spent_txo_sum) /
                100000000}{" "}
              BTC
            </i>
          </span>
        )}

        <br />

        <div>
          <p>Signature:</p>
          <textarea
            rows={5}
            cols={30}
            value={signature}
            onChange={(event) => setSignature(event.target.value)}
          />
        </div>

        <br />

        <div>
          <p>Message:</p>
          <textarea
            rows={5}
            cols={30}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </div>
      </form>

      <br />
      <br />
      <div
        style={{
          textAlign: "center",
          fontSize: "3.5rem",
          color: "#39C655",
        }}
      >
        {(isVerified && (
          <b
            style={{
              textAlign: "center",
              fontSize: "3.5rem",
              color: "#39C655",
            }}
          >
            Signature Verified!
          </b>
        )) || (
          <b
            style={{
              textAlign: "center",
              fontSize: "3.5rem",
              color: "#FF0000",
            }}
          >
            Signature Not Verified
          </b>
        )}
      </div>
    </Content>
  );
};

export default withLocation(Verify);
